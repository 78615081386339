import { useState } from "react"
import { sendRequest } from "../../apiClient";
import { RequestState } from "../../dataDefinitions/request";

export const useRegister = () => {

  const [registrationReqState, setRegistrationReqState] = useState<RequestState<{ newUserId: number }>>({ pending: false, error: '', data: null });

  const register = async (uid: string, email: string, password: string) => {
    setRegistrationReqState({ pending: true, error: '', data: null });
    const { data, error } = await sendRequest('POST', '/auth/register', { body: { uid, email, password } });
    if (error) {
      console.log("ERR", error)
      setRegistrationReqState({ pending: false, error: error?.toString() || 'Unknown error', data: null });
      return;
    }
    setRegistrationReqState({ pending: false, error, data })
  }

  return { registrationReqState, registerApi: { register } };
}