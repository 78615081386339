import { useEffect, useState } from 'react';
import { Alert, Box, Button, CircularProgress, InputAdornment, TextField, Typography } from '@mui/material';
import { useAuth } from '../lib/hooks/extData/auth';
import { LoginFormState } from '../lib/dataDefinitions/auth';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faEnvelope, faInputNumeric, faLockKeyhole } from '@fortawesome/pro-solid-svg-icons';

const LoginView = () => {
  const navigate = useNavigate();
  const { loginFormState, authApi } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [totpCode, setTotpCode] = useState("");
  const [logonError, setLogonError] = useState('');

  useEffect(() => {
    const queryEmail = new URLSearchParams(window.location.search).get('email');
    if (!Array.isArray(queryEmail)) {
      setEmail(queryEmail || '')
    }
  }, [])

  const logIn = async () => {
    setLogonError('');
    try {
      if (loginFormState === LoginFormState.CREDS) {
        await authApi.logIn(email, password);
      } else {
        // remove non-digits
        await authApi.logIn2fa(totpCode.replace(/\D/g, ''));
      }
    } catch (err: any) {
      setTotpCode('');
      setLogonError(err?.toString() || 'Unknown error');
    }
  }

  const resetLoginForm = () => {
    setPassword('');
    authApi.resetLoginForm();
  }

  const startPasswordReset = () => {
    navigate(`/startPasswordReset?email=${encodeURIComponent(email || '')}`)
  }

  return <>
    {loginFormState === LoginFormState.CREDS
      && <Box mb={3}>
        <Box marginBottom={2}>
          <Typography>E-mail address:</Typography>
          <TextField
            value={email}
            onChange={e => setEmail(e.target.value)}
            onKeyDown={e => e.key === 'Enter' ? logIn() : null}
            autoComplete='email'
            // sx={{ backgroundColor: '#FFFFFF', borderRadius: '4px' }}
            fullWidth
            variant="outlined"
            margin="dense"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faEnvelope} />
                </InputAdornment>
              ),
            }} />
        </Box>
        <Box>
          <Typography>Password:</Typography>
          <TextField
            value={password}
            onChange={e => setPassword(e.target.value)}
            onKeyDown={e => e.key === 'Enter' ? logIn() : null}
            autoComplete='current-password'
            fullWidth
            variant="outlined"
            margin="dense"
            type="password"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faLockKeyhole} />
                </InputAdornment>
              ),
            }} />
        </Box>
      </Box>}
    {loginFormState === LoginFormState.TOTP_CODE
      && <Box>
        <Typography textAlign="center" mb={3}>Two-Factor authentication is enabled for this user.
          Please provide a code from the Authenticator app.</Typography>
        <Box mb={3}>
          <Typography>Authenticator code:</Typography>
          <TextField
            value={totpCode}
            onChange={e => setTotpCode(e.target.value)}
            onKeyDown={e => e.key === 'Enter' ? logIn() : null}
            autoComplete='one-time-code'
            fullWidth
            variant="outlined"
            margin="dense"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faInputNumeric} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>}
    {logonError
      && <Alert severity="error" sx={{ my: 2 }}>{logonError}</Alert>}
    <Box sx={{ textAlign: 'center' }} mb={3}>
      {loginFormState === LoginFormState.PROGRESS
        ? <CircularProgress />
        : <div className='login-form__actions'>
          {loginFormState === LoginFormState.TOTP_CODE
            ? <Button className='login-form__action--secondary' onClick={resetLoginForm} color="info" startIcon={<FontAwesomeIcon icon={faArrowLeft} />}>Back to login</Button>
            : <Button className='login-form__action--secondary' onClick={startPasswordReset} color="info">Forgot password</Button>}
          <Button onClick={logIn} variant="contained" color="success" endIcon={<FontAwesomeIcon icon={faArrowRight} />}>Sign in</Button>
        </div>}
    </Box>
  </>
}

export default LoginView;